import * as React from "react";
import Layout from "../components/layout";
// markup
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import {Helmet} from "react-helmet";

const IndexPage = () => {
  return (
      <>
      <Helmet htmlAttributes={{ lang : 'en-uk' }}>
          <meta charSet="utf-8" />
          <title>Alan Macdonald</title>
          <meta name="google-site-verification" content="GdM6wmRBUy3yy9ikPkVTH6CZVFiqFNWP4GPmDmtuCDU" />
          <meta name="description" content="Paintings by Scottish artist Alan Macdonald."/>
          <meta name="keywords" content="Alan Macdonald Paintings, paintings by Alan Macdonald, Allan Macdonald, Alan Mcdonald, Allan Mcdonald, Alan MacDonald, artist, painter, painting, paintings, British artists, British painters, British art, Duncan of Jordanstone College of Art, Scottish art, Scottish painters"/>
          <meta name="author" content="Alan Macdonald"/>
          <meta charSet="utf-8" />
          <link rel="canonical" href="" />
          <meta property="og:title" content='Alan Macdonald' />
          <meta property="og:description" content="Paintings by Scottish artist Alan Macdonald." />
      </Helmet>
    <Layout>
      <main>
        <Link to="/all"><StaticImage style={{width: "100%", maxWidth: '100vw', minHeight: '100vh'}} className={"main_img"} objectFit="cover" placeholder="none" loading="eager" backgroundColor="rgb(22, 24, 16)" src="../images/the_temple_of_reason.jpg" alt="large painting 'the temple of reason'" /></Link>
      </main>
    </Layout>
          </>
  );
};

export default IndexPage;
